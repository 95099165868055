<template>
  <div class="theme-exhibition css-helper">
    <div class="content theme-scale">
      <div class="theme-exhibition-title">主题展览</div>
      <!-- <div>
        <div class="imgs-outer">
          <div
            v-for="(img, ind) in imgs"
            ref="imgs"
            :key="ind"
            :class="`con i${ind + 1} ${img.animation}`"
          >
            <div class="item">
              <div class="image-outer">
                <img :src="img.url" alt="" srcset="" />
              </div>
              <div class="text">
                <div>{{ img.name }}</div>
                <div>{{ img.intro }}</div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <div class="carousel-outer">
        <el-carousel height="700px" :autoplay="false" arrow="always">
          <el-carousel-item
            v-for="(item, index) in exhibitionList"
            :key="index"
          >
            <div class="imgs-outer flex flex-justify-between">
              <div
                v-for="(i, ind) in item"
                ref="imgs"
                :key="ind"
                :class="`con i${ind + 1}`"
                class="flex-item cursor-pointer"
                @click="$router.push(`/Introduction?id=${i.id}`)"
              >
                <div class="item">
                  <div class="image-outer">
                    <img
                      :src="i.images"
                      alt=""
                      srcset=""
                      class="image-inner"
                    />
                  </div>
                  <div class="text">
                    <div class="name">展览名称:{{ i.name }}</div>
                    <div>展览时间:{{ i.start_time }}-{{ i.end_time }}</div>
                  </div>
                </div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </div>
</template>

<script>
import { fetch } from "../../api/https.js";
import moment from "moment";
export default {
  data() {
    return {
      exhibitionList: [],
      // carouselItemNum: "",
    };
  },
  methods: {
    animate(el) {
      if (el) {
        el.classList.remove("fromTop");
        el.classList.remove("fromBottom");
      }
    },
    show() {
      let self = this;
      let delay = 300;
      let els = [this.$refs.imgs[0], this.$refs.imgs[1], this.$refs.imgs[2]];
      function trigger(ind) {
        if (els) {
          if (ind >= els.length) {
            return;
          }
          els.forEach((el) => self.animate(el));
          setTimeout(() => trigger(ind + 1), delay);
        }
      }
      this.$nextTick(() => {
        trigger(0);
      });
    },
  },
  async mounted() {
    let res = await fetch("/getIndexData");
    let list = res.data.zhanlan;
    list.forEach((item) => {
      item.start_time = moment(1000 * parseInt(item.start_time)).format(
        "YYYY/MM/DD"
      );
      item.end_time = moment(1000 * parseInt(item.end_time)).format(
        "YYYY/MM/DD"
      );
    });
    let newList = [];
    for (var i = 0; i < list.length; i += 3) {
      newList.push(list.slice(i, i + 3));
    }
    this.exhibitionList = newList;
    this.$root.$on("page3Animate", (val) => {
      if (val) {
        this.show();
      }
      if (!val) {
        let els = [this.$refs.imgs[0], this.$refs.imgs[1], this.$refs.imgs[2]];
        if (els) {
          els.forEach((el, index) => {
            if (index % 2 === 0) {
              if (el) {
                el.classList.add("fromBottom");
              }
            } else {
              if (el) el.classList.add("fromTop");
            }
          });
        }
      }
    });
  },
};
</script>

<style lang="scss">
.theme-exhibition {
  .el-carousel__arrow {
    background-color: transparent !important;
    font-size: 80px;
    font-weight: bolder;
    top: 220px;
    text-shadow: 6px 8px 5px rgba(100, 100, 100, 0.4);
    color: rgba(100, 100, 100, 0.4);
    :hover {
      color: #fff;
    }
  }
  .el-carousel__arrow--left {
    left: 0;
  }
  .el-carousel__arrow--right {
    right: 40px;
  }
}
</style>

<style lang="scss" scoped>
.theme-exhibition {
  width: 100%;
  box-sizing: border-box;
  position: relative;
  background: url("../../assets/img/powuguang/2/snuan.png") no-repeat;
  background-size: cover;
  @media screen and (max-width: 1440px) {
    .theme-scale {
      transform: scale(0.9);
    }
  }
  .theme-exhibition-title {
    text-align: center;
    color: #333333;
    font-size: 44.82px;
    // margin-top: 60px;
    margin-bottom: calc((100vh - 594px) / 7.6);
    padding-top: calc((100vh - 594px) / 5.5);
  }
  .con {
    &.i1 {
      top: 60px;
    }
    &.i2 {
      top: 60px;
    }
    &.i3 {
      top: 60px;
    }
    // position: absolute;
    top: 200px;
    margin: 0;
    overflow: visible;
    &.fromTop {
      .item {
        top: -800px;
        opacity: 0;
      }
    }
    &.fromBottom {
      .item {
        top: 800px;
        opacity: 0;
      }
    }
  }
  .carousel-outer {
    width: 1300px;
    margin: 0 auto;
  }
  .imgs-outer {
    margin: 0 auto;
    width: 973px;
    position: relative;
  }
  .item {
    transition: all 1s;
    position: relative;
    width: 272px;
    // height: 403px;
    height: 476px;
    top: 0;
    opacity: 1;
    box-shadow: 8px 8px 6px #b1a9a9;
    // margin: 0 20px;
    .image-outer {
      height: 403px;
      .image-inner {
        width: 272px;
        height: 403px;
      }
    }
    .text {
      background-color: #700c0c;
      color: #fff;
      font-size: 16px;
      font-weight: lighter;
      height: 73px;
      padding: 10px;
      line-height: 26px;
      box-sizing: border-box;
      text-align: left;
      .name {
        max-width: 300px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
@media screen and (max-width:1000px) {
  .theme-exhibition-title{
    padding-top: 40px !important;
    margin-bottom: 60px !important;
  }
}

</style>