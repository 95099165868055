export function getCurrentDate(date, format) {
    var currentdate = new Date(date);
    var year = currentdate.getFullYear();
    var month = currentdate.getMonth() + 1;
    var strDate = currentdate.getDate();
    if (month >= 1 && month <= 9) {
        month = "0" + month;
    }
    if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
    }
    return year + format + month + format + strDate;
}