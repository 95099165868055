<template>
  <div class="list css-helper">
    <div class="left left_lan">
      <div
        :class="{
          'left-img-animate': animateStart,
          left_img_out: !animateStart,
        }"
        class="cursor-pointer canvas-ourer"
        @click="$router.push('/digit')"
      >
      <div class="titleImg"><img src="../../assets/index/wxtu.png" alt=""></div>
        <div class="canvas" id="containerb"></div>
      </div>
      <!-- <img
        :class="{
          'left-img-animate': animateStart,
          left_img_out: !animateStart,
        }"
        class="cursor-pointer"
        @click="$router.push('/digit')"
        src="../../assets/index/left.png"
        alt=""
        srcset=""
      /> -->
      <!-- :class="{
          'left-img-animate': animateStart,
          left_img_out: !animateStart,
        }" -->
    </div>
    <div class="right lan_right">
      <ul class="nav">
        <li
          :class="{ 'active-list-item': item.active }"
          v-for="(item, index) in categoryList"
          :key="index"
          @click="changeType(item)"
        >
          {{ item.cate_name }}
        </li>
      </ul>
      <div class="list-lbt">
        <!-- :class="{
          'right-animition': animateStart,
          right_lbt_out: !animateStart,
        }" -->
        <el-carousel
          :class="{
            'right-animition': animateStart,
            right_lbt_out: !animateStart,
          }"
          :autoplay="false"
          indicator-position="outside"
          ref="carousel"
        >
          <el-carousel-item
            v-for="(item, index) in currentCategory"
            :key="index"
          >
            <div
              class="right-carousel-item flex flex-justify-end"
              @click="$router.push(`/digitDetails?id=${item.id}`)"
            >
              <div class="flex-item image-outer flex-1">
                <!-- <img src="../../assets/index/iii.png" alt="" class="image" /> -->
                <img :src="item.tj_images" alt="" class="image" />
              </div>
              <!-- <div class="keys flex-item" v-html="item.keys"></div> -->
              <div class="desc flex-item">
                <span v-html="item.des"></span>
              </div>
              <div class="time flex-item">{{ item.dynasdy }}</div>
              <img
                src="../../assets/index/LOGO.png"
                alt=""
                class="logo flex-item"
              />
              <div class="name flex-item">{{ item.name }}</div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </div>
</template>

<script>
import { fetch } from "../../api/https.js";
import * as THREE from "../../three.module.js";
// import { OrbitControls } from "../../OrbitControls.js";
import { FBXLoader } from "../../three/FBXLoader";
export default {
  data() {
    return {
      currentCategory: [],
      categoryList: [],
      animateStart: false,
      list: [
        {
          name: "金银器",
          active: true,
        },
        {
          name: "铜器",
          active: false,
        },
        {
          name: "玉石器",
          active: false,
        },
        {
          name: "漆器",
          active: false,
        },
      ],
      three_size:60
    };
  },
  async mounted() {
    this.calculateChildMenuSize()
    window.addEventListener("resize", this.calculateChildMenuSize);
    

    let self = this;
    let objUrl =
      "http://www.symuseum.cn/api/data/uploads/attachment/20210422/f24e7156f4278721756c69ec9f9b2112.fbx";
    let containerEl = document.getElementById("containerb");
    let width = containerEl.getBoundingClientRect().width;
    let height = containerEl.getBoundingClientRect().height;
    const scene = new THREE.Scene();
    console.log(this.three_size)
    const camera = new THREE.PerspectiveCamera(this.three_size, width / height, 0.05, 1000);
    camera.position.z = 5;
    const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
   
    renderer.setClearAlpha(0);
    renderer.setSize(width, height);
    function render() {
    
      renderer.render(scene, camera);
    }
    containerEl.appendChild(renderer.domElement);
    const ambientLight = new THREE.AmbientLight(0xffffff, 2);
    scene.add(ambientLight);

    let raycaster = new THREE.Raycaster();

    
    let object;
    function loadModel() {
      object.position.y = -1.5;
      object.rotation.y = 0.0;
      object.rotation.x = 0.5;
      object.scale.set(0.01, 0.01, 0.01);
      scene.add(object);
      render();
    }
    const manager = new THREE.LoadingManager(loadModel);

    const loader = new FBXLoader(manager);
    loader.load(
      objUrl,
      function (obj) {
        object = obj;
      },
    );    

    setInterval(function () {
      if (object) {
        object.rotation.y += 0.01;
        render();
      }
    }, 80);

    if (window.screen.availWidth < 600) {
      this.animateStart = true;
    } else {
      this.$root.$on("page2Animate", (val) => {
        this.animateStart = val;
      });
    }
 
    let category = (await fetch("/collect/cate")).data;
    category.forEach((e) => {
      e.active = false;
    });
    category[0].active = true;
    let currentCategory = await fetch("/collect/index", {
      type: 1,
      cate_id: category[0].id,
      sy_status: 2,
    });
    this.currentCategory = currentCategory.data.data;
    this.categoryList = category.splice(0, 4);
  },
  methods: {
    calculateChildMenuSize(){
            var offsetWid = document.documentElement.clientWidth;
            console.log(offsetWid);
            if(offsetWid<1680){
                this.three_size = 60
            }else{
                this.three_size = 50
                // this.fen = 4
            }
        },
    async changeType(currentItem) {
      this.currentCategory = []
      this.$refs.carousel.setActiveItem(0);
      for (var item of this.categoryList) {
        item.active = false;
      }
      currentItem.active = true;
      let res = await fetch("/collect/index", {
        type: 1,
        cate_id: currentItem.id,
        sy_status: 2,
      });
      this.currentCategory = res.data.data;
    },
  },
};
</script>

<style scoped>
>>> .el-carousel__arrow--left {
  top: 60%;
}
>>> .el-carousel__arrow--right {
  top: 60%;
}
>>> .el-carousel__arrow {
  width: 60px;
  height: 60px;
  font-size: 36px;
  font-weight: bolder;
  background-color: rgba(255, 255, 255, 0.21);
  color: rgba(100, 100, 100, 0.4);
}
>>> .el-carousel__container {
  height: 65% !important;
}
.el-carousel--horizontal {
  height: 100%;
}
</style>
<style lang="scss" scoped>
.list {
  height: 100vh;
  background: url("../../assets/img/powuguang/2/nuan.png") no-repeat;
  background-size: cover;
  display: flex;
  justify-content: start;
  box-sizing: border-box;
  .left {
    width: 36%;
    height: 100%;
    position: relative;
    img {
      // position: absolute;
      // top: -100%;
      // left: 0;
      width: 100%;
      height: 100%;
      // opacity: 0;
    }

    .left-img-animate {
      animation: left_img 1.5s forwards;
    }
    .left_img_out {
      animation: left_img_out 1.5s forwards;
    }
  }
  .lan_right {
    height: auto;
  }
  .left_lan {
    height: 100%;
    position: relative;
    .canvas-ourer {
      background: url('../../assets/index/wenzi.png') center center no-repeat;
      background-size: 100% 100%;
      height: 100%;
      padding-top: 20%;
      box-sizing: border-box;
      position: relative;
    }
    .canvas {
      // background-color: pink;
      width: 100%;
      height: 100%;
      margin-left: 10%;
    }
  }
  .titleImg{
    position: absolute;
    top: 28px;
    right: 46px;
    width: 150px;
    height: 370px;
  }
  .right {
    width: 64%;
    padding-top: 6%;
    height: 100%;
    position: relative;
    .right-carousel-item {
      background: url("../../assets/index/carouselBg.png") center center
        no-repeat;
      // background-color: pink;
      height: 100%;
      background-size: 100% 100% !important;
      color: #fff;
      padding: 21% 14% 0 0%;
      box-sizing: border-box;
      .logo {
        width: 33px;
        height: 33px;
        margin-left: 1%;
      }
      .image-outer {
        height: 118%;
        text-align: center;
        padding-right: 4%;
        .image {
          transform: translateY(-30%);
          height: 100%;
          width: auto;
        }
      }

      .desc {
        height: 76%;
        line-height: 20px;
        padding-right: 2%;
        span{
          display: block;
          width: 100px;
        }
      }
      .keys,
      .desc,
      .time,
      .name {
        font-size: 14px;
        writing-mode: vertical-rl;
      }
      .time {
        width: 16px;
      }
      .name {
        font-size: 32px;
        font-family: zihun110hao;
        font-weight: normal;
        color: #ffffff;
        line-height: 40px;
        height: 80%;
        padding-left: 6%;
      }
    }
    .nav {
      width: 50%;
      height: 36px;
      margin: 0 auto 30px;
      display: flex;
      justify-content: space-around;
      min-width: 480px;
      transform: translate(-50%, 50%);
      z-index: 10000;
      position: absolute;
      left: 50%;
      li {
        flex: 1;
        padding: 0 10px;
        text-align: center;
        height: 40px;
        line-height: 40px;
        cursor: pointer;
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #ffffff;
      }
    }
    .right-animition {
      animation: right_lbt 1.5s forwards;
    }
    .right_lbt_out {
      animation: right_lbt_out 1.5s forwards;
    }
    .list-lbt {
      // position: absolute;
      // right: -100%;
      // width: 100%;
      height: 100%;
      animation-iteration-count: 1;
      // opacity: 0;
      & img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .active-list-item {
    background: url("../../assets/img/powuguang/1/bronze.png") center center
      no-repeat;
    background-size: contain;
    height: 44px;
  }

  // 轮播图指示器的大小
  & /deep/ .el-carousel__button {
    width: 50px;
    height: 50px;
  }
  // 调整轮播图指示器位置
  & /deep/ .el-carousel__indicators--horizontal {
    bottom: 30px;
  }
  // 轮播图选中时指示器的样式
  & /deep/ .el-carousel__indicator.is-active button {
    background: url("../../assets/img/qlinlogo.png") no-repeat;
  }

  // 轮播图未选中时的指示器的样式
  & /deep/ .el-carousel__indicator button {
    background: url("../../assets/img/qinlogo2.png") no-repeat;
  }
  @keyframes left_img {
    0% {
      opacity: 0;
      -webkit-transform: translateY(-100%);
      transform: translateY(-100%);
    }
    50% {
      opacity: 0.1;
    }
    70% {
      opacity: 0.2;
    }
    80% {
      opacity: 0.3;
    }
    to {
      opacity: 1;
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }
  @keyframes left_img_out {
    0% {
      opacity: 1;
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    50% {
      opacity: 0.3;
    }
    70% {
      opacity: 0.2;
    }
    80% {
      opacity: 0.1;
    }
    to {
      opacity: 0;
      -webkit-transform: translateY(-100%);
      transform: translateY(-100%);
    }
  }
  @keyframes right_lbt_out {
    0% {
      opacity: 1;
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
    50% {
      opacity: 0.3;
    }
    70% {
      opacity: 0.2;
    }
    80% {
      opacity: 0.1;
    }
    to {
      opacity: 0;
      -webkit-transform: translateX(100%);
      transform: translateX(100%);
    }
  }
  @keyframes right_lbt {
    0% {
      opacity: 0;
      -webkit-transform: translateX(100%);
      transform: translateX(100%);
    }
    50% {
      opacity: 0.1;
    }
    70% {
      opacity: 0.2;
    }
    80% {
      opacity: 0.3;
    }
    to {
      opacity: 1;
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
  }
}
#containerb{
  margin-left: -40px;
}
@media screen  and (max-width: 1550px) and (min-width: 1440px){
  
    .titleImg img{
    position: absolute;
    top: 20px;
    right: 20px;
    width: 90px !important;
    height:250px !important;
  }
}
@media screen  and (max-width: 1439px) and (min-width: 1200px){
  
    .titleImg img{
    position: absolute;
    top: 20px;
    right: 20px;
    width: 100px !important;
    height:250px !important;
  }
}
</style>