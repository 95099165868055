<template>
<div style="height: 100%">
  <div
    class="home css-helper"
    @wheel="onWheel"
    :style="{transform: `translateY(-${ this.showFooter ? `360%` : `${(this.currPage*100)}%`} `}"
  >
    <!-- 轮播图 -->
    <banner-com class="item item-page"></banner-com>

    <!-- 博物馆资讯页面 -->
    <bowuguan-message id="message" class="item item-page"></bowuguan-message>

    <!-- 博物馆物品详情页面 -->
    <bowuguan-list id="list" class="item item-page"></bowuguan-list>
    <!-- 博物馆主题页面 -->
    <buwuguan-theme-exhibition
      id="themeExhibition"
      class="item item-page"
    ></buwuguan-theme-exhibition>

    <!-- 博物馆底部页面 -->
    <footerComTop></footerComTop>

    <footer-com id="foot"></footer-com>
    <!-- </vue-scroll> -->

    <!-- 动画 下方按钮 -->
    <div
      class="scroll-d cursor-pointer"
      :style="{
        top: `calc(${(currPage + 1) * 100}% - 80px)`,
      }"
      @click="pageDown()"
      v-show="currPage !== pageCount - 1 && scrollBtnShow"
    >
      <scroll-down></scroll-down>
    </div>

    <!-- 侧边栏 -->
    
  </div>
  <div>
      <sidebar-com
        class="sidebar"
        :class="{ sidebarShow: isShow }"
        v-show="currPage !== 0 && currPage !== 2"
        :style="{ top: `calc(${currPage * 100}%)` }"
      ></sidebar-com>
    </div>
  </div>

  
</template>
<script>
// @ is an alias to /src
import headerCom from "@/components/header/header.vue";
import bannerCom from "@/components/index-page/banner.vue";
import bowuguanMessage from "@/components/index-page/message.vue";
import bowuguanList from "@/components/index-page/bowuguan-list.vue";
import buwuguanThemeExhibition from "@/components/index-page/theme-exhibition.vue";
import scrollDown from "@/components/scroll-down.vue";
import sidebarCom from "@/components/sidebar.vue";
import footerComTop from "@/components/footerTop.vue";
import footerCom from "@/components/footer.vue";
export default {
  name: "Home",
  data() {
    return {
      scrollBtnShow:true,
      currPage: 0,
      pageCount: 0,
      wheelBlock: false,
      showFooter: false,
      footerHeight: 527,
      isShow: false,
    };
  },

  watch: {
    currPage() {
      this.currPage === 2
        ? this.$root.$emit("page2Animate", true)
        : this.$root.$emit("page2Animate", false);

      this.currPage === 3
        ? this.$root.$emit("page3Animate", true)
        : this.$root.$emit("page3Animate", false);
      if (this.currPage === 1) {
        this.isShow = true;
      }
    },
  },
  mounted() {
   
  },
  methods: {
    onWheel(e) {
      if (window.screen.availWidth < 600) {
        return;
      } else {
        e.stopPropagation();
        e.preventDefault();
        if (this.wheelBlock) {
          return;
        }
        this.wheelBlock = true;
        if (e.deltaY > 0) {
          this.pageDown();
        }
        if (e.deltaY < 0) {
          this.pageUp();
        }
        setTimeout(() => {
          this.wheelBlock = false;
        }, 1500);
      }
    },
    pageDown() {
      let pages = this.$el.querySelectorAll(".item-page");
      let pageCount = pages.length;
      this.pageCount = pageCount;
      if (this.currPage === pageCount - 1) {
        this.showFooter = true;
      } else {
        this.currPage++;
      }
    },
    pageUp() {
      if (this.showFooter) {
        this.showFooter = false;
      } else {
        this.currPage--;
        if (this.currPage < 0) {
          this.currPage = 0;
        }
      }
    },
  },
  created() {},
  destroyed() {
    //离开该页面需要移除这个监听的事件
  },
  components: {
    headerCom,
    bannerCom,
    bowuguanMessage,
    scrollDown,
    bowuguanList,
    buwuguanThemeExhibition, // 博物馆主题页面
    footerCom, // 底部页面
    sidebarCom, // 侧边栏
    footerComTop,
  },
};
</script>
<style lang="scss" scoped>
@media screen and (max-width: 1000px) {
  .item {
    height: 683px !important;
  }
}
.sidebar {
  position: fixed;
  left: 90%;
  top: 59% !important;
  margin-top: -310px !important;
}
.sidebarShow {
  animation: sadBarShow 1.5s linear;
}
.home {
  overflow: visible !important;
  transition: transform 0.4s linear;
  .item {
    height: 100%;
    overflow-y: hidden !important;
  }
}
.head {
  position: fixed;
  z-index: 100000;
  height: 80px;
  width: 100%;
  background-color: #fff;
  // visibility: hidden;
}
.scroll-d {
  position: fixed;
  left: 50%;
  z-index: 1000000;
  transform: translateX(-50%);
  animation: scrollDown 2s linear infinite;
}
@keyframes scrollDown {
  0% {
    transform: translate(-50%, 10px);
  }
  50% {
    transform: translate(-50%, -10px);
  }
  100% {
    transform: translate(-50%, 10px);
  }
}
@keyframes sadBarShow {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.2;
  }
  to {
    opacity: 1;
    
  }
}
.BMap_bubble_title {
  color: #b26c30;
}
// .scroll {
//   transition: 0.3s all ease-out;
// }
</style>