<template>
  <div class="swiper-container _index-banner">
    <div class="cb-slideshowbox">
      <ul class="cb-slideshow">
        <li
          v-for="(item, key) in swiper"
          :key="key"
          :class="key == 0 ? 'active' : ''"
        >
          <span
            :style="{ backgroundImage: `url(${item.plug_ad_pic})` }"
            @click="$router.push(item.plug_ad_url)"
          ></span>
        </li>
      </ul>
      <ul class="slide-icon" style="text-align: center">
		  <li
			v-for="(item, key) in swiper"
			:key="key"
			ref="slide_icons"
			:class="key == 0 ? 'active' : ''"
		  >
		  </li>
        <!-- <li class="active"></li>
        <li></li> -->
      </ul>
    </div>
  </div>
</template>
<script>
import $ from "./jquery-3.3.1.min.js";
import { fetch } from "../../api/https";
export default {
  data() {
    return {
      swiper: [],
    };
  },
  methods: {},
  watch:{
	swiper(val) {
		this.$nextTick(()=>{
		let self = this;
    const slide_icons = this.$refs['slide_icons']
			$(function () {
			  var winheight = $(window).height();
			  $("._index-banner .cb-slideshowbox").height(winheight);
			  function slideimg() {
				if (activeimg == self.swiper.length - 1) {
				  activeimg = -1;
				}
				$("._index-banner .cb-slideshow li").removeClass("active fade");

				$("._index-banner .slide-icon li").removeClass("active");
				$("._index-banner .cb-slideshow li:eq(" + (activeimg + 1) + ")").addClass("active");
				$("._index-banner .slide-icon li:eq(" + (activeimg + 1) + ")").addClass("active");
				if (activeimg == -1) {
				  fadeimg = self.swiper.length - 1;
				} else {
				  fadeimg = activeimg;
				}
				$("._index-banner .cb-slideshow li:eq(" + fadeimg + ")").addClass("fade");
				activeimg = $(".cb-slideshow li.active").index();

				$("._index-banner .cb-slideshow li #titleimg")
				  .removeClass()
				  .removeAttr("style");
				$("._index-banner .cb-slideshow li:eq(" + activeimg + ")  #titleimg").addClass("titleimg wow titleimgfadeIn");

				if (activeimg == self.swiper.length - 1) {
				  activeimg = -1;
				}
			  }
			  var activeimg = 0,
				fadeimg = 0;
			  if ($("._index-banner .cb-slideshow li").length == 1) {
				$("._index-banner .slide-icon").hide();
				$("._index-banner .cb-slideshow li span").css({
				  animation: "none",
				  opacity: "1",
				});
			  } else {
				var time = setInterval(function () {
				  slideimg();
				}, 6000);
			  }
			  $(slide_icons).click(function () {
				if ($(this).hasClass("active")) {
				  return;
				}

				clearInterval(time);
				fadeimg = $("._index-banner .cb-slideshow li.active").index();
				activeimg = $(this).index();

				$("._index-banner .cb-slideshow li").removeClass("active fade");
				$("._index-banner .slide-icon li").removeClass("active");
				$("._index-banner .cb-slideshow li:eq(" + activeimg + ")").addClass("active");
				$("._index-banner .slide-icon li:eq(" + activeimg + ")").addClass("active");
				$("._index-banner .cb-slideshow li:eq(" + fadeimg + ")").addClass("fade");

				$("._index-banner .cb-slideshow li #titleimg")
				  .removeClass()
				  .removeAttr("style");
				$("._index-banner .cb-slideshow li:eq(" + activeimg + ")  #titleimg").addClass("titleimg wow titleimgfadeIn");
				activeimg = $("._index-banner .cb-slideshow li.active").index();
				time = setInterval(function () {
				  slideimg();
				}, 6000);
			  });
			});
		})
	}
  },
  mounted() {
    let self = this;
    fetch("/getmenu").then((res) => {
      document.title = res.data[0].menu_seo_title;
    });
    fetch("/getlun", { typeid: 1 }).then((res) => {
      this.swiper = res.data;
    });
     fetch('/getmenu')
                .then(res => {
                  document.title = res.data[0].menu_seo_title
     })
  },
};
</script>

<style lang="scss">
._index-banner {
  height: 100vh;

  * {
    margin: 0;
    padding: 0;
  }
  ul {
    list-style: none;
  }

  @-webkit-keyframes titleimgfadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes titleimgfadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  .titleimgfadeIn {
    -webkit-animation-name: titleimgfadeIn;
    animation-name: titleimgfadeIn;
    animation: titleimgfadeIn 3s forwards 0.5s;
  }

  .cb-slideshowbox {
    // width: 100%;
    height: 768px;
    position: relative;
    top: 0;
    left: 0;
    overflow: hidden;
  }

  .cb-slideshow {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 0;
  }
  .cb-slideshow li {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }
  .cb-slideshow li a {
    display: block;
    height: 100%;
  }
  .cb-slideshow li span {
    width: 100%;
    height: 100%;
    display: block;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    opacity: 0;
    z-index: 0;
    -webkit-backface-visibility: hidden;
    font-size: 0;
  }
  .cb-slideshow li .titleimg {
    position: absolute;
    top: 23%;
    left: 36.5%;
    width: 0;
    opacity: 0;
  }
  .cb-slideshow li.active .titleimg {
    width: 27%;
  }
  .cb-slideshow li.active {
    z-index: 2;
  }
  .cb-slideshow li.active span {
    animation: imageAnimation 6s linear infinite 0s;
  }
  .cb-slideshow li.fade span {
    animation: fadeAnimation 6s linear infinite 0s;
  }
  @keyframes imageAnimation {
    0% {
      opacity: 0;
      animation-timing-function: ease-in;
    }
    10% {
      opacity: 0.5;
    }
    30% {
      opacity: 0.8;
    }
    70% {
      opacity: 1;
      transform: scale(1.07);
      animation-timing-function: ease-out;
    }
    100% {
      opacity: 1;
      transform: scale(1.1);
    }
  }
  @keyframes fadeAnimation {
    0% {
      opacity: 1;
      transform: scale(1.1);
    }
    20% {
      opacity: 0;
      -webkit-transform: scale(1.1) translateY(-20%);
    }
    60% {
      opacity: 0;
      transform: scale(1.1) translateY(-100%);
    }
    100% {
      opacity: 0;
    }
  }

  .no-cssanimations .cb-slideshow li span {
    opacity: 1;
  }

  ////--------

  .cb-slideshowbox .slide-icon {
    position: absolute;
    bottom: 66px;
    z-index: 2;
    text-align: right;
    right: 0;
    width: 100%;
    height: 150px;
  }

  .cb-slideshowbox .slide-icon li {
    width: 50px;
    height: 50px;
    background: url("../../assets/img/heibaiCopy.png") top center no-repeat !important;
    opacity: 1;
    display: inline-block;
    margin: 0 5px;
    cursor: pointer;
  }

  .cb-slideshowbox .slide-icon li.active {
    width: 50px;
    height: 50px;
    background: url("../../assets/img/heibai.png") top center no-repeat !important;
    opacity: 1;
    display: inline-block;
    margin: 0 5px;
    cursor: pointer;
  }
}
._index-banner * {
  width: 100%;
  height: 100%;
}
@media screen and(max-width: 1000px){
  ._index-banner .cb-slideshow li span{
    background-size: 100% 100%;
  }
  ._index-banner .cb-slideshow li{
    height: 821px;
  }
  ._index-banner .cb-slideshowbox .slide-icon{
    top: 690px !important;
  }
}
</style>
