<template>
  <div class="sidebar-container css-helper content-scale">
    <div class="sidebar flex flex-column">
      <a href="http://www.symuseum.cn/pano/" class="vaMath" target='_blank'>
          <img src="../assets/index/vr.png" alt="">
          <p>VR数字博物馆</p>
      </a>
      <div
        class="sidebar-item cursor-pointer"
        v-for="(item, index) in sidebarItems"
        :key="index"
        @click="tiaozhuan(item.url)"
      >
        <div>
          <img :src="item.img" alt="" class="image" />
        </div>
        <div class="name">{{ item.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sidebarItems: [
        //{
        //   img: require("../assets/index/vr.png"),
        //   name: "VR数字博物馆",
        //   url: "/pano",
        // },
        {
          img: require("../assets/index/museum.png"),
          name: "AR珍品品鉴",
          url: "/digit",
        },
        {
          img: require("../assets/index/ticket.png"),
          name: "门票预约",
          url: "/ticket",
        },
        {
          img: require("../assets/index/cs.png"),
          name: "我的中心",
          url: "/ucenter",
        },
      ],
    };
  },
  methods:{
    tiaozhuan(url){
      if(url == '/ucenter'){
          if(!localStorage.getItem('token')){
              this.$router.push('/login')
          }else{
        this.$router.push(url)

          }
      }else{
        this.$router.push(url)

      }
      

    }
  }
};
</script>


<style scoped lang="scss">
@media screen and (max-width: 1440px) {
  .content-scale {
    transform: scale(0.85);
    transform-origin: top right;
    margin-top: calc((100vh - 600px) / 2) !important;
  }
}
.sidebar-container {
  margin-top: calc((100vh - 670px) / 2);
}
.vaMath{
  display: block;
  text-align: center;
    width: 80px;
    height: 66.5px;
    background-color: #fff;
    margin: 0 auto;
    padding-top: 10px;
    box-sizing: border-box;
    margin-bottom: 10px;
    img {
      margin-bottom: 2px;
    }
    p{
      height: 12px;
      font-size: 12px;
      font-family: "PingFang SC Heavy";
      font-weight: bolder;
      color: #8f0302;
      line-height: 11px;
    }
}
.sidebar {
  width: 100px;
  height: 540px;
  background: url("../assets/index/sidebarbg.png") center center no-repeat;
  background-size: 100px 540px;
  padding-top: 10px;
  box-sizing: border-box;
  .sidebar-item {
    text-align: center;
    width: 80px;
    height: 66.5px;
    background-color: #fff;
    margin: 0 auto;
    padding-top: 10px;
    box-sizing: border-box;
    margin-bottom: 10px;
    .image {
      margin-bottom: 2px;
    }
    .name {
      // width: 71px;
      height: 12px;
      font-size: 12px;
      font-family: "PingFang SC Heavy";
      font-weight: bolder;
      color: #8f0302;
      line-height: 11px;
    }
  }
}
</style>