<template>
  <div class="bowuguan-message css-helper">
    <div class="content wrap-content-message content-scale">
      <div class="top">
        <ul class="clearfix">
          <li class="start-message left-f">
            <p class="current-date">{{ currentDate }}</p>
            <div class="is-start-guan" v-if=" week_day != 1">今日闭馆</div>
            <div class="is-start-guan" v-else>今日开馆</div>
            
            <div class="start-time">
              <ul v-show=" week_day == 1">
                <li>
                  <p class="time">{{ start }}</p>
                  <span> 开馆 </span>
                </li>
                <li>
            	  <p class="time">{{ stop }}</p>
                  <span> 停止入馆 </span>
                </li>
                <li>
            	  <p class="time">{{ end }}</p>
                  <span> 闭馆 </span>
                </li>
              </ul>
            </div>
            <div class="other-message">
              <ul>
                <li @click="$router.push('/digital')" class="cursor-pointer">
                  <img
                    class=""
                    src="../../assets/index/message-map.png"
                    alt=""
                    srcset=""
                  />
                  <p>导览地图</p>
                </li>
                <li @click="goTrafficRoutes" class="cursor-pointer">
                  <img
                    src="../../assets/index/message-traffic.png"
                    alt=""
                    srcset=""
                  />
                  <p>交通路线</p>
                </li>
                <!-- 20240801修改@dou -->
<!--                <li @click="$router.push('/ticket')" class="cursor-pointer">
                  <img
                    src="../../assets/index/message-ticket.png"
                    alt=""
                    srcset=""
                  />
                  <p>门票预约</p>
                </li> -->
                <li class="cursor-pointer" @click="goNotice">
                  <img
                    src="../../assets/index/message-notice.png"
                    alt=""
                    srcset=""
                  />
                  <p>游览须知</p>
                </li>
              </ul>
            </div>
            <div class="detial-message">
              <div class="flex">
                <div class="flex-item">&bull;</div>
                <p class="flex-item" v-if=" today == '2022-6-2' || today == '2022-6-3' ||  today == '2022-6-4' ||  today == '2022-6-5' ||  today == '2022-5-4' ||  today == '2022-2-5' ||  today == '2022-2-6'">
                  端午节期间（6.3-6.5）开闭馆时间：9:00-17:30，馆内展厅全部开放<br>
                  入馆参观需验证健康码，测体温，刷身份证，提供72小时之内核酸阴性证明。根据疫情防控要求，馆内一楼展厅只在周末和节假日开放
				  <!-- 根据疫情防控要求，顺义区博物馆自2022年5月1日起暂停对外开放，具体恢复时间另行通知。因临时闭馆给大家带来的不便深表歉意！感谢理解与支持！  -->
                </p>
                <!-- 20240801修改@dou 参观群众凭预约码进馆-->
                <p class="flex-item" v-else>
                  除法定节假日，博物馆全年实行周一闭馆。团队请提前拨打81475286转1预约参观。顺义好礼开放时间为周二至周日 上午9:00-11:30。
                  <!-- 根据疫情防控要求，顺义区博物馆自2022年5月1日起暂停对外开放，具体恢复时间另行通知。因临时闭馆给大家带来的不便深表歉意！感谢理解与支持！  -->                </p>
              </div>
              <div class="flex">
                <div>&bull;</div>
                <p>闭馆前半小时停止入馆。</p>
              </div>
            </div>
          </li>
          <li class="all-area-guan left-f">
            <!-- <h3>全景博物馆</h3>-->
            <a href="http://www.symuseum.cn/pano/"  target='_blank' style="display: block;">
				<img src="../../assets/activityDetail/quanjing.png" alt="" srcset="" />
			</a>
          </li>
          <li class="message flex flex-column left-f">
            <div class="message-top flex flex-item">
              <div class="flex-item flex-item-center news-outer">
                <img src="../../assets/index/news.png" alt="" class="news" />
              </div>
              <div class="flex-item flex-item-center volunteer-outer">
                <img
                  src="../../assets/index/volunteer.png"
                  alt=""
                  class="volunteer"
                  @click="$router.push('/volunteer')"
                />
              </div>

              <div class="flex-item more flex-item-center">
                <span
                  class="cursor-pointer more-news"
                  @click="$router.push('/news')"
                  >更多+</span
                >
              </div>
            </div>
            <div class="newslist-outer flex-item flex-1">
              <div
                v-for="(news, index) in newsList"
                :key="index"
                class="newslist"
                @mousedown.stop="an"
                @mouseup.stop="goNews(news.id)"
              >
                <span class="date cursor-pointer">{{ news.news_time }}</span
                ><span class="content cursor-pointer">{{
                  news.news_title
                }}</span>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="bottom">
        <div class="left">
          <el-carousel height="240px" :autoplay="isAuto" ref="carousel">
            <el-carousel-item
              v-for="(activity, index) in activityList"
              :key="index"
            >
              <img
                class="message-img"
                :src="activity.tj_images"
                alt=""
                srcset=""
                @click="$router.push(`/activitydetail?id=${activity.id}`)"
              />
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="right">
          <div class="titles">
            <img src="../../assets/img/powudoudong.png" alt="" srcset="" />
            <span
              class="mores cursor-pointer"
              @click="$router.push('/hotList')"
              >更多+</span
            >
          </div>
          <ul class="bo-act-list">
            <li
              @mouseover="changeCarousel(index)"
              @mouseout="carouselAuto()"
              @mousedown.stop="an"
              @mouseup.stop="goActivity(activity.id)"
              v-for="(activity, index) in activityList"
              :key="index"
            >
              <img
                src="../../assets/titleImg.png"
                alt=""
                class="bo-act-list-img"
              />
              <span class="word-content line-show-dot">
                {{ activity.name }}
              </span>
              <p>{{ activity.create_time }}</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCurrentDate } from "@/api/utils.js";
import { fetch } from "@/api/https.js";
import moment from "moment";
export default {
  data() {
    return {
      isAuto: true,
      date: new Date(),
	  week_day:1,
	  week:1,
	  today: '',
      start: '09:00',
      stop: '17:00',
      end: '17:30',
      leftList: [{ img: "../" }],
      activityList: [],
      newsList: [],
    };
  },
  created() {
    this.getIsOpen();
  },
  computed: {
    currentDate() {
      // getCurrentDate(this.date, "/");
	  // this.week_day = this.date.getDay();
      return getCurrentDate(this.date, "/");
    },
  },
  async mounted() {
    let res = await fetch("/getIndexData");
    let activityList = res.data.activity;
    activityList.forEach((item) => {
      item.create_time = moment(1000 * parseInt(item.create_time)).format(
        "MM-DD"
      );
    });
    let newsList = res.data.news;
    newsList.forEach((item) => {
      item.news_time = moment(1000 * parseInt(item.news_time)).format("MM-DD");
    });
    this.newsList = newsList;

    this.activityList = activityList;
    if (this.activityList.length > 4) {
      this.activityList.splice(4, a.length - 1);
    }
  },
  methods: {
    an() {
      //鼠标按下
      this.timeLength = new Date().getTime();
    },
    goNews(id) {
      //鼠标松开 判断鼠标点击的时间
      this.oldtime = new Date().getTime();
      if (this.oldtime - this.timeLength < 130) {
        this.$router.push(`/deta?id=${id}`);
      }
    },
    goActivity(id) {
      this.oldtime = new Date().getTime();
      if (this.oldtime - this.timeLength < 130) {
        this.$router.push(`/activitydetail?id=${id}`);
      }
    },
	getIsOpen() {
	    // this.$router.push(`/todayIsOpen`);
		fetch("/todayIsOpen").then((res) => {
		  this.week_day = res.data.open;
		  this.week = res.data.week;
		  this.today = res.data.d[0].day;
		  this.start = res.data.d[0].start;
		  this.stop = res.data.d[0].stop;
		  this.end = res.data.d[0].end;
		});
	},
    changeCarousel(index) {
      this.isAuto = false;
      this.$refs.carousel.setActiveItem(index);
    },
    carouselAuto() {
      this.isAuto = true;
    },
    goTrafficRoutes() {
      this.$router.push("/visit");
      localStorage.setItem("gotraffic", "go");
    },
    goNotice() {
      this.$router.push("/visit");
      localStorage.setItem("gonotice", "go");
    },
  },
};
</script>

<style lang="scss" scoped>
@media screen and (max-width:1000px) {
  .bowuguan-message{
    padding-top: 30px !important;
  }
}
.bowuguan-message {
  @media screen and (max-width:1440px) {
    .content-scale{
      transform: scale(0.85);
    }
  }
  @keyframes icon {
    0% {
      transform: translate(0, 5px);
    }
    50% {
      transform: translate(0, -5px);
    }
    100% {
      transform: translate(0, 5px);
    }
  }
  width: 100%;
  // height: 100vh;
  background: url("../../assets/index/indexMessageBg.png") center center
    no-repeat;
  background-size: cover;
  padding-top: calc((100vh - 740px) / 2);
  box-sizing: border-box;
  .wrap-content-message {
    max-width: 1286px;
  }
  .content {
    margin: 0 auto;
    height: 500px;
    & .top {
      height: 396px;
      box-sizing: border-box;
      margin-bottom: 10px;
      & .current-date {
        text-align: center;
        margin-bottom: 20px;
        height: 15px;
        font-size: 21px;
        font-family: Arial;
        font-weight: 400;
        color: #fadeb7;
      }
      & ul {
        height: 100%;
      }
      & ul li {
        //    float: left;
        height: 100%;
      }

      & ul .message {
        width: 482px;
        box-sizing: border-box;
        // height: 395px !important;
        .newslist-outer {
          .newslist {
            line-height: 74px;
            padding: 0 30px;
            box-sizing: border-box;
            color: #deb4b4;
            font-weight: normal;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 15px !important;
            .date {
              padding-right: 10px;
            }
            &:hover {
              color: #fff;
            }
            &:last-child {
              height: 73px;
            }
            &:nth-child(odd) {
              background-color: #800404;
            }
          }
        }
        .message-top {
          padding-top: 10px;
          height: 100px;
          box-sizing: border-box;
          .more {
            color: #deb4b4;
            font-weight: bolder;
            .more-news:hover {
              color: #fff;
            }
          }
          .news-outer {
            padding: 0 36px 0 49px;
            .news {
              width: 104px;
              height: 74px;
            }
          }
          .volunteer-outer {
            padding-right: 86px;
          }
          .volunteer {
            width: 134px;
            height: 49px;
          }
        }

        // & img {
        //   width: 100%;
        // }
      }

      & ul .start-message {
        background: url("../../assets/img/start-mess-back.png") no-repeat;
        box-sizing: border-box;
        padding: 0px 40px;
        width: 408px;
        .current-date {
          margin-top: 30px;
          color: #fadeb7;
        }
        .is-start-guan {
          text-align: center;
          // margin: 5px 0;
          // font: normal bold 36px "PingFang-SC-Bold";
          // color: #ffffff;
          // width: 141px;
          height: 35px;
          font-size: 36px;
          font-family: 'PingFang SC';
          font-weight: 540;
          color: #ffffff;
          // line-height: 149px;
        }

        .detial-message {
          color: #f4f0ec;
          margin: 15px auto;
          width: 90%;
          p {
            // text-align: left;
            // margin: 5px 0;
            width: 276px;
            margin: 0 auto;
            // height: 24px;
            line-height: 21px;
            font-size: 13px;
            margin-bottom: 10px;
            font-family: 'PingFang SC';
            font-weight: 300;
            color: #ffffff;
            // line-height: 24px;
          }
          // width: 80%;
          // font-size: 12px;
          // margin: 0 auto;
        }

        & .start-time {
          width: 100%;
          margin: 20px auto 0;
          ul {
            display: flex;
            justify-content: space-between;
            li {
              display: flex;
              flex: 1;
              flex-direction: column;
              justify-content: center;
              align-items: center;

              .time {
                font-size: 18px;
                color: #fff;
              }

              span {
                font-size: 14px;
                color: #faf7f3;
              }
            }
          }
        }
      }

      & ul .all-area-guan {
        width: 396px;
        img {
          width: 100%;
          height: 100%;
        }
      }

      & ul .message {
        position: relative;
        height: 396px;
        background: #8f0302;
        // img {
        //   width: 100%;
        //   vertical-align: middle;
        // }
        .detial-message {
          position: absolute;
          top: 76px;
          overflow: hidden;
          width: 100%;
          div {
            padding: 0 12px;
            cursor: pointer;
            width: 100%;
            overflow: hidden;
            display: flex;
            justify-content: start;
            height: 56px;
            line-height: 56px;
            color: #deb4b4;
            font-size: 15px;
            font-family: 'PingFang SC';
            font-weight: bold;
            color: #ffffff;
            line-height: 60px;
          }
          .word-content {
            margin-left: 20px;
            width: 77%;
          }

          // 奇数时
          div:nth-child(odd) {
            background-color: #7f0403;
          }
          div:nth-child(even) {
            background-color: #8f0301;
          }

          div:hover {
            color: #f8f2f2;
          }
        }
      }

      & ul .other-message ul {
        // border: 1px solid black;
        height: 70px;
        margin: 10px auto 20px;
        width: 88%;
        display: flex;
        justify-content: space-between;
        li {
          // float: left;
          width: 65px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;

          // height: 70px;
          // margin-right: 10px;
          img:hover {
            animation: icon 0.5s 0s linear;
          }
          p {
            font-size: 14px;
            color: #e8d2ad;
            // width: 54px;
            height: 14px;
            font-size: 14px;
            font-family: 'PingFang SC';
            // font-weight: 500;
            font-weight: 300;

            color: #fff1d1;
            // line-height: 24px;
          }
        }
      }
    }

    & .bottom {
      height: 240px;
      display: flex;
      justify-content: start;
      & .left {
        width: 722px;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
        }
        // 轮播图指示器的大小
        & /deep/ .el-carousel__button {
          width: 10px !important;
          height: 10px !important;
          border-radius: 10px;
        }
      }
      & .right {
        width: 564px;
        background: url("../../assets/img/powuguangyouche.png") no-repeat;
        background-size: cover;
        padding: 50px 30px 0;
        box-sizing: border-box;

        .titles {
          // text-align: center;
          padding-left: 168px;
          position: relative;
          img {
            margin-top: -30px;
            vertical-align: middle;
          }
          .mores {
            margin-top: -10px;
            line-height: 30px;
            position: absolute;
            top: 50%;
            right: -19px;
            transform: translate(-50%, -50%);
            &:hover {
              color: #e03939;
            }
          }
        }

        .bo-act-list {
          color: #000;
          font-size: 14px;
          font-family: 'PingFang SC';
          font-weight: 500;
          color: #333333;
          // line-height: 186px;
          .bo-act-list-img {
            width: 16px;
            height: 13px;
            margin-top: 8px;
            margin-right: 6px;
          }
          li {
            display: flex;
            justify-content: space-between;
            margin: 5px;
            line-height: 30px;
            color: #333333;
          }
          .word-content {
            width: 85%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .bo-act-list li:hover {
          cursor: pointer;
          color: #e03939;
        }
      }
    }
  }
  // 轮播图指示器的大小
  .el-carousel__button {
    width: 10px !important;
    height: 10px !important;
  }
}


</style>