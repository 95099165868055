<template>
  <footer>
    <div class="top css-helper">
      <ul class="content">
        <li class="help-center">
          <div class="center cursor-pointer" @click="$router.push('/help')">
            <img
              src="../assets/img/helpcenter.png"
              alt=""
              srcset=""
              class="center-icon"
            />
            <span> 帮助中心 </span>
          </div>
        </li>
        <li class="option-back">
          <div class="center cursor-pointer" @click="$router.push('/feedback')">
            <img
              src="../assets/img/optionback.png"
              alt=""
              srcset=""
              class="center-icon"
            />
            <span> 意见反馈 </span>
          </div>
        </li>
        <li class="tour-service">
          <div class="center cursor-pointer" @click="$router.push('/visit')">
            <img
              src="../assets/img/tourservice.png"
              alt=""
              srcset=""
              class="center-icon"
            />
            <span> 导览服务 </span>
          </div>
        </li>
      </ul>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
footer {
  height: 232px;
  @keyframes icon {
    0% {
      transform: translate(0, 8px);
    }
    50% {
      transform: translate(0, -8px);
    }
    100% {
      transform: translate(0, 8px);
    }
  }
  .content {
    width: 68%;
  }
  .top {
    background-image: url("../assets/FootTop.png");
    height: 232px;
    background-size: 100% 102%;

    & ul {
      margin: 0 auto;
      height: 100%;
      display: flex;
      justify-content: start;
      & li {
        color: #fff;
        font-size: 16px;
        flex: 1;
        height: 100%;
        display: flex;

        // flex-direction: column;
        justify-content: center;
        align-items: center;
        opacity: 0.9;
        div {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          span {
            margin-top: 30px;
          }
        }
      }
      // & li:nth-child(even) {
      //     // background-color: #8d6e3b;
      // }

      // & li:nth-child(odd) {
      //     // background-color: #672726;
      // }
    }
  }
  .center-icon:hover {
    animation: icon 0.5s 0s linear;
  }
}
</style>